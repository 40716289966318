.soclogin {
    display: grid;
    justify-content: center;
    @media (max-width: $mobile-max) {
        grid-template-columns: repeat(2, auto);
        grid-gap: 48px;
    }
    @media (min-width: $tablet-p-min) {
        grid-auto-flow: column;
        grid-gap: 40px;
    }
}
.soclogin__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @media (max-width: $mobile-max) {
        width: 80px;
        height: 80px;
    }
    @media (min-width: $tablet-p-min) {
        width: 64px;
        height: 64px;
    }
}
.soclogin__button--fbk {
    background-color: #3F51B5;
}
.soclogin__button--vkt {
    background-color: #4A76A8;
}
.soclogin__button--twt {
    background-color: #03A9F4;
}
.soclogin__button--okl {
    background-color: #EE8208;
}
.soclogin__icon {
    fill: #ffffff;
    @media (max-width: $mobile-max) {
        transform: scale(1.25);
    }
}

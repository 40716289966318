.search {
    display: flex;
    align-items: center;
    @media (max-width: $mobile-max) {
        flex-direction: row-reverse;
    }
}
.search__input {
    flex-grow: 1;
    min-width: 0;
    border: none;
    &:focus {
        outline: none;
    }
    @media (max-width: $mobile-max) {
        margin-left: 12px;
    }
    @media (min-width: $tablet-p-min) {
        margin-right: 12px;
        font-weight: 600;
    }
}
.search__button {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

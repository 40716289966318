.newslist {
    --grid-template-columns: 1fr;
    position: relative;
    padding: 16px;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        background-color: $main;
        opacity: .08;
    }
}
.newslist--gridable {
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        --grid-template-columns: repeat(3, 1fr);
        padding: 24px;
    }
}
.newslist__feed {
    grid-template-columns: var(--grid-template-columns);
    display: grid;
    grid-gap: 16px;
    padding: var(--padding);
}
.newslist__link {
    float: left;
    margin-right: 6px;
    line-height: 13px;
    font-size: 12px;
    letter-spacing: .04em;
    font-weight: bold;
    text-transform: uppercase;
}
.newslist__item:nth-child(1) {
    --meta-link__display: inline;
    @media (max-width: $mobile-max) {
        --meta--line-height: 13px;
        --meta--font-size: 12px;
    }
    @media (min-width: $tablet-p-min) {
        --meta--line-height: 17px;
        --meta--font-size: 16px;
    }
}
.newslist__item:nth-child(n+2) {
    --meta-link__display: none;
    @media (max-width: $tablet-p-max) {
        --meta--line-height: 11px;
        --meta--font-size: 10px;
    }
    @media (min-width: $tablet-l-min) {
        --meta--line-height: 13px;
        --meta--font-size: 12px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        margin-top: 6px;
    }
}
.newslist__meta {
    line-height: var(--meta--line-height);
    font-size: var(--meta--font-size);
    letter-spacing: .04em;
    font-weight: bold;
    text-transform: uppercase;
    color: $text-light;
    & > a {
        display: var(--meta-link__display);
    }
}
.newslist__title {
    margin-top: 4px;
    line-height: 17px;
    font-size: 16px;
    font-weight: 600;
    &:not(:hover) {
        color: $text;
    }
    &:hover {
        color: $text-alt;
    }
    &:visited {
        color: $text-placeholder;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        line-height: 15px;
        font-size: 14px;
    }
}
.newslist__more {
    width: 100%;
    margin-top: 16px;
}
.newslist__loader {
    width: 100%;
    margin-top: 16px;
}

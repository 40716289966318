.popular {
    padding: 16px 16px 24px;
    background: #454545;
}
.popular__container {
    position: relative;
}
.popular__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #AAAAAA;
}
.popular__wrapper {
    height: auto !important;
}
.popular__slide {
    @media (max-width: $tablet-l-max) {
        width: 213px !important;
    }
    @media (min-width: $desktop-min) {
        width: 272px !important;
    }
}
.popular__nav {
    --button-size: 48px;
    position: absolute;
    z-index: 1;
    left: calc((var(--button-size) + var(--gap)) * -1);
    right: calc((var(--button-size) + var(--gap)) * -1);
    top: 50%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;
    @media (max-width: 1377px) {
        display: none;
    }
}
.popular__button {
    width: var(--button-size);
    height: var(--button-size);
    pointer-events: auto;
    background-color: #636363;
    background-repeat: no-repeat;
    background-size: 17px 30px;
}
.popular__button--prev {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7891 0.351562L1.03906 14.1016L0.179688 15L1.03906 15.8984L14.7891 29.6484L16.5859 27.8516L3.73438 15L16.5859 2.14844L14.7891 0.351562Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: 14px center;
}
.popular__button--next {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.21094 29.6484L15.9609 15.8984L16.8203 15L15.9609 14.1016L2.21094 0.351562L0.414063 2.14844L13.2656 15L0.414062 27.8516L2.21094 29.6484Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: 18px center;
}
.popular__button--disabled {
    opacity: .2;
}
.popular__list {
    display: grid;
    row-gap: 32px;
    @media (max-width: $mobile-max) {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        column-gap: 24px;
    }
}

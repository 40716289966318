* > .featured {
    margin-top: 32px;
}
.featured {
    display: grid;
    grid-gap: var(--gap);
    @media (max-width: $mobile-max) {
        grid-template-columns: 1fr 1fr;
        padding-top: 32px;
        padding-bottom: 32px;
    }
    @media (min-width: $tablet-p-min) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        padding-bottom: 24px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        padding-top: 24px;
    }
}
.featured__post {
    display: flex;
    flex-direction: column;
    min-width: 0;
    & > * {
        flex-shrink: 0;
    }
}
.featured__post:nth-child(1) {
    --featured-display: block;
    @media (max-width: $mobile-max) {
        --title--font-size: 28px;
        --title--line-height: 27px;
        grid-column: 1 / -1;
    }
    @media (min-width: $tablet-p-min) {
        grid-column: 1 / 3;
        grid-row: 1 / -1;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        --title--font-size: 30px;
        --title--line-height: 31px;
    }
    @media (min-width: $tablet-l-min) and (max-width: $tablet-l-max) {
        --title--font-size: 36px;
        --title--line-height: 37px;
    }
    @media (min-width: $desktop-min) {
        --title--font-size: 40px;
        --title--line-height: 41px;
    }
}
.featured__post:nth-child(n+2) {
    --featured-display: none;
    @media (max-width: $mobile-max) {
        --title--font-size: 16px;
        --title--line-height: 17px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        --title--font-size: 18px;
        --title--line-height: 19px;
    }
    @media (min-width: $tablet-l-min) and (max-width: $tablet-l-max) {
        --title--font-size: 20px;
        --title--line-height: 21px;
    }
    @media (min-width: $desktop-min) {
        --title--font-size: 24px;
        --title--line-height: 26px;
    }
}
.featured__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.33%;
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.featured__meta {
    margin-top: 8px;
}
.featured__title {
    margin-top: 4px;
    font-size: var(--title--font-size);
    line-height: var(--title--line-height);
    font-weight: 800;
    &:not(:hover) {
        color: $text;
    }
    &:hover {
        color: $text-alt;
    }
    &:visited {
        color: $text-placeholder;
    }
}
.featured__description {
    display: var(--featured-display);
    color: $text-alt;
    @media (max-width: $tablet-l-max) {
        margin-top: 6px;
    }
    @media (max-width: $tablet-p-max) {
        line-height: 13px;
        font-size: 12px;
    }
    @media (min-width: $tablet-l-min) {
        line-height: 15px;
        font-size: 14px;
    }
    @media (min-width: $desktop-min) {
        margin-top: 10px;
    }
}

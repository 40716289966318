.form__warning {
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 11px;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: $text-light;
}
.form__field {
    padding: 16px;
    width: 100%;
    height: 64px;
    line-height: 25px;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.01em;
    border: none;
    box-shadow: inset 0 0 0 1px #DDDDDD;
}
.form__field--textarea {
    height: 82px;
}
.form__field + .form__field {
    margin-top: -1px;
}
.form__submit {
    width: 100%;
    height: 64px;
    margin-top: 20px;
    padding: 0;
    line-height: 64px;
    font-size: 24px;
    letter-spacing: -0.01em;
    font-weight: 800;
    text-align: center;
    border-radius: 0;
    border: none;
    color: #ffffff;
    &:not(:hover) {
        background-color: $main;
    }
    &:hover {
        background-color: $main-light;
    }
}

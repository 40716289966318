.category {
    display: grid;
    grid-gap: var(--gap);
    @media (min-width: $tablet-p-min) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, min-content) 1fr;
        padding-top: 28px;
    }
}
.category__post:nth-child(1) {
    @media (max-width: $mobile-max) {
        --meta__line-height: 13px;
        --meta__font-size: 12px;
        --meta__margin: 8px;
    }
    @media (min-width: $tablet-p-min) {
        --meta__line-height: 17px;
        --meta__font-size: 16px;
        --meta__margin: 14px;
        margin-top: -28px;
        grid-row: 1 / 5;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        --title__line-height: 19px;
        --title__font-size: 18px;
    }
    @media (min-width: $tablet-l-min) {
        --title__line-height: 21px;
        --title__font-size: 20px;
    }
}
.category__post:nth-child(n+2) {
    --meta__margin: 4px;
    @media (max-width: $tablet-p-max) {
        --meta__line-height: 11px;
        --meta__font-size: 10px;
    }
    @media (min-width: $tablet-l-min) {
        --meta__line-height: 13px;
        --meta__font-size: 12px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        --title__line-height: 15px;
        --title__font-size: 14px;
    }
    @media (min-width: $tablet-l-min) {
        --title__line-height: 17px;
        --title__font-size: 16px;
    }
}
.category__meta {
    margin-bottom: var(--meta__margin);
    line-height: var(--meta__line-height);
    font-size: var(--meta__font-size);
    letter-spacing: .04em;
    font-weight: bold;
    color: $text-light;
    text-transform: uppercase;
    & > * {
        &:nth-child(n+2) {
            margin-left: 8px;
        }
    }
}
.category__image {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    & > a {
        & > img {
            display: block;
            width: 100%;
        }
    }
    @media (max-width: $mobile-max) {
        max-width: 213px;
    }
}
.category__title {
    display: block;
    font-weight: 800;
    &:not(:hover) {
        color: $text;
    }
    &:hover {
        color: $text-alt;
    }
    &:visited {
        color: $text-placeholder;
    }
    @media (max-width: $mobile-max) {
        line-height: 17px;
        font-size: 16px;
    }
    @media (min-width: $tablet-p-min) {
        line-height: var(--title__line-height);
        font-size: var(--title__font-size);
    }
}
.category__text {
    margin-top: 8px;
    color: $text-alt;
    @media (max-width: $tablet-p-max) {
        line-height: 13px;
        font-size: 12px;
    }
    @media (min-width: $tablet-l-min) {
        line-height: 15px;
        font-size: 14px;
    }
}
.category__more {
    display: block;
    margin-top: 16px;
    @media (min-width: $tablet-p-min) {
        display: none;
    }
}

.postshort {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
        flex-shrink: 0;
    }
}
.postshort__image {
    margin-bottom: 8px;
    @media (max-width: $mobile-max) {
        width: 213px;
    }
    @media (min-width: $tablet-p-min) {
        width: 100%;
        & > img {
            width: 100%;
        }
    }
}
.postshort__meta {

}
.postshort__title {
    margin-top: 4px;
    font-weight: var(--title__font-weight, 600);
    &:not(:hover) {
        color: $text;
    }
    &:hover {
        color: $text-alt;
    }
    &:visited {
        color: $text-placeholder;
    }
    @media (max-width: $mobile-max) {
        line-height: 17px;
        font-size: 16px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        line-height: var(--title__line-height, 19px);
        font-size: var(--title__font-size, 18px);
    }
    @media (min-width: $tablet-l-min) {
        line-height: var(--title__line-height, 21px);
        font-size: var(--title__font-size, 20px);
    }
}
.postshort__text {
    margin-top: 6px;
    letter-spacing: 0.01em;
    @media (max-width: $tablet-p-max) {
        line-height: 13px;
        font-size: 12px;
    }
    @media (min-width: $tablet-l-min) {
        line-height: 15px;
        font-size: 14px;
    }
}

.comment {
    line-height: 21px;
    font-size: 15px;
    font-family: Arial;
}
.comment__header {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 6px;
    align-items: baseline;
    width: fit-content;
    font-weight: bold;
    &::before {
        content: '';
        grid-column: 2 / 3;
        align-self: center;
        width: 3px;
        height: 3px;
        margin-top: 2px;
        background-color: #babcc3;
        border-radius: 50%;
    }
}
.comment__author {
    color: #385baf;
}
.comment__date {
    line-height: 12px;
    font-size: 11px;
    color: #636576;
}
.comment__text {
    margin-top: 8px;
    line-height: 19px;
    word-break: break-word;
}
.comment__quote {
    margin-top: 4px;
    line-height: 24px;
    font-size: 11px;
    font-weight: bold;
    color: #636576;
}

 .nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.nav__categories {
    m-nl();
    display: flex;
    @media (max-width: $mobile-max) {
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;
    }
    @media screen {
        justify-content: space-between;
    }
    & > li {
        flex-shrink: 0;
        font-weight: 800;
        text-transform: uppercase;
        &:not(.is-active) {
            & > * {
                &:not(:hover) {
                    --highlight__opacity: 0;
                }
                &:hover {
                    --highlight__opacity: 1;
                }
                &::before {
                    background-color: #888888;
                    opacity: var(--highlight__opacity);
                    transition-property: opacity;
                }
            }
        }
        &.is-active {
            & > * {
                &::before {
                    background-image: linear-gradient(180deg, #FFBF1B 0%, #FF7A00 100%);
                }
            }
        }
        & > * {
            display: block;
            margin-top: 0;
            margin-bottom: 0;
            color: $text;
            @media (max-width: $mobile-max) {
                padding-top: 9px;
                padding-bottom: 9px;
                line-height: 20px;
                font-size: 16px;
            }
            @media (min-width: $tablet-p-min) and (max-width: $tablet-l-max) {
                padding-top: 24px;
                padding-bottom: 24px;
                line-height: 15px;
                font-size: 12px;
            }
            @media (min-width: $desktop-min) {
                padding-top: 19px;
                padding-bottom: 19px;
                line-height: 20px;
                font-size: 16px;
            }
            @media (min-width: $tablet-p-min) {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 8px;
                    clip-path: polygon(0 0, calc(100% - 4px) 0, 100% 100%, 4px 100%);
                }
            }
        }
    }
}
.nav__pages {
    m-nl();
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: $tablet-p-min) {
        display: none;
    }
    & > li {
        &:nth-last-child(1) {
            margin-top: 10px;
        }
        & > a {
            line-height: 32px;
            font-size: 10px;
            font-weight: bold;
            transition-property: color;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            &:not(:hover) {
                color: $text-light;
            }
            &:hover {
                color: $text-alt;
            }
        }
    }
}

.post-list__title {
    margin-bottom: 16px;
    font-weight: 800;
    line-height: 1;
    font-size: 24px;
    letter-spacing: -0.01em;
    @media (min-width: $tablet-p-min) {
        display: none;
    }
}
.post-list__feed {
    display: grid;
    grid-row-gap: 32px;
}
.post-list__feed--category {
    --title__font-weight: 800;
    grid-gap: var(--gap);
    @media (min-width: $tablet-p-min) {
        grid-template-columns: repeat(6, 1fr);
        & > * {
            &:nth-child(1) {
                grid-column: 1 / 4;
            }
            &:nth-child(2) {
                grid-column: 4 / 7;
            }
            &:nth-child(3n+3) {
                grid-column: 1 / 3;
            }
            &:nth-child(3n+4) {
                grid-column: 3 / 5;
            }
            &:nth-child(3n+5) {
                grid-column: 5 / 7;
            }
        }
    }
    & > * {
        &:nth-child(-n+2) {
            @media (min-width: $desktop-min) {
                --title__font-size: 24px;
                --title__line-height: 26px;
            }
        }
    }
}
.post-list__more {
    width: 100%;
    margin-top: var(--gap);
}
.post-list__loader {
    margin-left: auto;
    margin-right: auto;
}

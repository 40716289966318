.page {
    line-height: 1.4;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    background-color: $bg;
    @media (max-width: $mobile-max) {
        padding-top: 56px;
    }
    @media (min-width: $desktop-min) {
        padding-top: calc(140px + 32px);
    }
}
.page__disabled {
    overflow: hidden;
}
.page__header {
    z-index: 3; /* Чтобы перекрыть Я.Директ */
    @media (max-width: $mobile-max) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    @media (min-width: $tablet-l-min) and (max-width: $tablet-l-max) {
        margin-bottom: 24px;
    }
    @media (min-width: $desktop-min) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

// Common styles of the project

html, body {
    height: 100%;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}
*,
::before,
::after {
    box-sizing: border-box;
    transition-property: none;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
.container {
    max-width: 1216px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: $tablet-p-min) {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.move-up {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    z-index: 4;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    color: #444444;
    transition-property: opacity;
    opacity: 0;
    pointer-events: none;
    @media (max-width: $mobile-max) {
        left: 16px;
        bottom: 16px;
    }
    @media (min-width: $tablet-p-min) {
        left: 24px;
        bottom: 24px;
    }
}
.move-up--visible {
    opacity: 1;
    pointer-events: auto;
}

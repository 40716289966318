.single-post {
    & > * {
        &:nth-child(n+2) {
            margin-top: 32px;
        }
    }
}
.article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
        flex-shrink: 0;
    }
}
.article__title {
    order: 1;
    margin: 4px 0 0;
    line-height: 36px;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: -0.01em;
    word-break: break-word;
}
.article__header {
    width: 100%;
    order: 0;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    line-height: 11px;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: $text-light;
}
.article__meta {
    display: flex;
    align-items: center;
}
.article__views {
    display: flex;
    align-items: center;
    & > svg {
        margin-right: 4px;
    }
}
.article__content {
    order: 2;
    margin-top: 24px;
    max-width: 100%;
}
.article__thumb {
    display: inline; /* Removes margin when parent is not grid */
    margin: 0 0 16px;
}
.article__thumb > img {
    display: block;
}
.article__image-caption {
    margin-top: 4px;
    line-height: 1;
    font-size: 10px;
    color: $text-placeholder;
}
.article__excerpt {
    font-weight: 600;
    line-height: 21px;
    font-size: 16px;
    letter-spacing: 0.01em;
    & > p {
        margin-top: 0;
        margin-bottom: 0;
    }
    & > * + * {
        margin-top: 1em;
    }
}
.article__text {

}
.article__aside {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 28px 24px 32px;
    line-height: 27px;
    font-size: 20px;
    text-align: center;
    font-family: 'Noto Serif', serif;
    font-style: italic;
    letter-spacing: 0.01em;
    background-color: #ffffff;
    @media (max-width: $mobile-max) {
        margin-left: -16px;
        margin-right: -16px;
    }
}
.article__text {
    word-break: break-word;
}
.article__source {
    order: 3;
    max-width: 100%;
    font-weight: bold;
    line-height: 14px;
    font-size: 13px;
    letter-spacing: 0.04em;
    color: $text-light;
    & > a {
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        text-overflow: ellipsis;
    }
}
.article__footnotes {
    order: 4;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: auto 1fr;
    margin-top: 24px;
    margin-bottom: 0;
    padding: 16px;
    font-family: 'Noto Serif', serif;
    line-height: 13px;
    font-size: 12px;
    background-color: #ffffff;
    color: $text-light;
    & > dt {
        text-align: right;
    }
    & > dd {
        margin: 0;
    }
    @media (max-width: $mobile-max) {
        margin-left: -16px;
        margin-right: -16px;
    }
}
.article__subscribe {
    order: 5;
    margin-top: 32px;
}
.article__nav {
    order: 6;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    margin-top: 32px;
    padding: 16px;
    font-weight: 800;
    background-color: #F5EAE0;
    @media (max-width: $mobile-max) {
        margin-left: -16px;
        margin-right: -16px;
        line-height: 17px;
        font-size: 16px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        line-height: 19px;
        font-size: 18px;
    }
    @media (min-width: $tablet-l-min) {
        line-height: 21px;
        font-size: 20px;
    }
    & > a {
        &:not(:hover) {
            color: $text;
        }
        &:hover {
            color: $text-alt;
        }
    }
}
.article__prev {
    text-align-last: left;
}
.article__next {
    text-align-last: right;
}
.article__also {
    margin-bottom: 30px;
    @media (max-width: $mobile-max) {
        margin-left: -16px;
        margin-right: -16px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $tablet-p-max) {
        margin-top: 16px;
    }
    @media (min-width: $tablet-l-min) {
        float: right;
        max-width: 282px;
        margin-left: var(--gap);
    }
}
.article__partners {
    align-self: stretch;
    order: 5;
    margin-top: 32px;
}
.article__share {
    display: flex;
    order: 6;
    margin-top: 32px;
}
@supports (display: grid) {
    @media (min-width: $tablet-l-min) {
        .article {
            display: grid;
        }
        .article__title {
            grid-column: 1;
            grid-row: 2 / 3;
        }
        .article__header {
            grid-column: 1;
            grid-row: 1 / 2;
        }
        .article__content {
            grid-column: 1;
            grid-row: 3 / 4;
        }
        .article__content--grid {
            display: grid;
            grid-template-columns: 2fr calc(282px + var(--gap));
            grid-template-areas: 'thumb also' 'excerpt excerpt' 'text text' 'subscribe subscribe';
            align-items: start;
        }
        .article__thumb {
            grid-area: thumb;
        }
        .article__excerpt {
            grid-area: excerpt;
        }
        .article__text {
            grid-area: text;
        }
        .article__source {
            grid-column: 1;
            grid-row: 4 / 5;
        }
        .article__footnotes {
            grid-column: 1;
            grid-row: 5 / 6;
        }
        .article__subscribe {
            grid-column: 1;
            grid-row: 7 / 8;
        }
        .article__nav {
            grid-column: 1;
            grid-row: 9 / 10;
        }
        .article__share {
            grid-column: 1;
        }
    }
    @media (min-width: $desktop-min) {
        .article__content--grid {
            align-items: end;
        }
        .article__share {
            position: sticky;
            bottom: 24px;
            flex-direction: column;
            grid-row: 1 / 7;
            grid-column: 1;
            margin-left: -92px;
            align-self: end;
        }
    }
}


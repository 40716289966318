$bg = #F4F4F4;
$text-light = #888888;
$text = #000000;
$text-alt = #444444;
$text-placeholder = #AAAAAA;
$main = #FF7A00;
$main-light = #FF9A3D;

// Media
$mobile-max = 767px;
$tablet-p-min = 768px;
$tablet-p-max = 1023px;
$tablet-l-min = 1024px;
$tablet-l-max = 1365px;
$desktop-min = 1366px;

:root {
    --gap: 16px;
    @media (min-width: $desktop-min) {
        --gap: 24px;
    }
}

a {
    text-decoration: none;
    transition-property: color;
    &:not(:hover) {
        color: $main;
    }
    &:hover {
        color: $main-light;
    }
}
.hamburger {
    --size: 20px;
    --line-opacity: 1;
    --rotate: 0;
    --translate: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    width: var(--size);
    height: 18px;
}
.hamburger--active {
    --line-opacity: 0;
    --rotate: 45deg;
    --translate: calc(var(--size) * .30);
}
.hamburger__line {
    width: 100%;
    height: 2px;
    background-color: hsl(0, 0%, 0%);
    transition-property: opacity, transform;
    &:nth-child(1) {
        transform: translateY(var(--translate)) rotate(var(--rotate));
    }
    &:nth-child(2) {
        opacity: var(--line-opacity);
    }
    &:nth-child(3) {
        transform: translateY(calc(var(--translate) * -1)) rotate(calc(var(--rotate) * -1));
    }
}
.search-button {
    --size: 20px;
    --glass-rotate: 0;
    --glass-translate: 0;
    --glass-width: calc(var(--size) * .8);
    --glass-height: calc(var(--size) * .8);
    --glass-radius: 50%;
    --handle-scale: .8;
    --offset: 0px;
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    background-color: transparent;
    border: none;
    color: hsl(0, 0%, 0%);
}
.search-button--active {
    @media (max-width: $mobile-max) {
        --glass-rotate: -45deg;
        --glass-translate: 0;
        --glass-width: 2px;
        --glass-height: var(--size);
        --glass-radius: 0;
        --handle-scale: 2;
        --offset: calc(var(--size) / 11);
    }
}
.search-button__glass {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: var(--glass-width);
    height: var(--glass-height);
    box-shadow: 0 0 0 2px currentColor inset;
    border-radius: var(--glass-radius);
    transition: all ease .2s;
    transform: translatex(calc(var(--offset) * -1)) translatey(calc(var(--offset) * -1)) rotate(var(--glass-rotate));
    transform-origin: bottom;
}
.search-button__handle {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    height: calc(var(--size) / 2);
    transition: all ease .2s;
    background-color: currentColor;
    transform: translatex(calc(var(--offset) * 1)) translatey(calc(var(--offset) * -1)) rotate(45deg) scale(1, var(--handle-scale));
    transform-origin: bottom;
}
.more {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: .04em;
    text-transform: uppercase;
    text-align: center;
    background-color: #ffffff;
    color: $main;
}

.header {
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);;
}
.header--fixed {
    @media (min-width: $desktop-min) {
        position: fixed !important;
        & .header__top {
            display: none;
        }
        & .header__nav {
            &::before {
                opacity: 1;
            }
        }
    }
}
.header__top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobile-max) {
        height: 56px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-l-max) {
        padding-top: 24px;
    }
    @media (min-width: $desktop-min) {
        padding-top: 32px;
        padding-bottom: 10px;
    }
}
.header__button,
.header__logo,
.header__info {
    z-index: 3;
}

.header__button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: transparent;
    & > * {
        pointer-events: none;
    }
}
.header__button--menu {
    @media (min-width: $tablet-p-min) {
        opacity: 0;
        pointer-events: none;
    }
}
.header__logo {
    @media (max-width: $mobile-max) {
        width: 78px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-l-max) {
        width: 100px;
    }
    @media (min-width: $desktop-min) {
        width: 126px;
    }
    & > img {
        display: block;
    }
}
.header__search {
    z-index: 3;
    position: absolute;
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition-property: opacity;
    @media (max-width: $mobile-max) {
        top: 0;
        left: 0;
        right: 24px;
        bottom: 0;
    }
    @media (min-width: $tablet-p-min) {
        right: 0;
        height: 32px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-l-max) {
        width: 265px;
    }
    @media (min-width: $desktop-min) {
        width: 345px;
    }
}
.header__search--active {
    opacity: 1;
    pointer-events: auto;
    box-shadow: inset 0 -1px #DDDDDD;
}
.header__nav {
    display: flex;
    & > * {
        flex-grow: 1;
    }
    @media (max-width: $mobile-max) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        flex-direction: column;
        padding-top: 56px;
        background-color: #ffffff;
        opacity: 0;
        pointer-events: none;
        transition-property: opacity;
    }
    @media (min-width: $desktop-min) {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -56px;
            width: 24px;
            height: 16px;
            opacity: 0;
            transition-property: opacity;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12.436l10.912 15.93-13.05-.053L0 0z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='14.182' y1='7.958' x2='10.685' y2='15.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFBF1B'/%3E%3Cstop offset='1' stop-color='%23FF7A00'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}
.header__nav--active {
    opacity: 1;
    pointer-events: auto;
}

.login {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    &::after {
        content: '';
        height: 40px;
    }
}
.login__logo {
    width: 112px;
}
.login__form {
    max-width: 568px;
}

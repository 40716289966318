.footer {
    color: #888888;
    background-color: #333333;
    @media (max-width: $tablet-l-max) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    @media (min-width: $desktop-min) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}
.footer__container {
    @media (min-width: $desktop-min) {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
    }
}
.footer__title, .footer__more {
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    @media (max-width: $tablet-l-max) {
        font-size: 10px;
        line-height: 11px;
    }
    @media (min-width: $desktop-min) {
        line-height: 13px;
        font-size: 12px;
    }
}
.footer__more {
    margin-top: 8px;
    margin-bottom: 8px;
}
.footer__text {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    margin-top: 8px;
    @media (max-width: $tablet-l-max) {
        font-size: 13px;
        line-height: 12px;
    }
    @media (min-width: $desktop-min) {
        line-height: 15px;
        font-size: 14px;
    }
}
.footer__info {
    display: grid;
    margin-top: 24px;
    @media (max-width: $tablet-l-max) {
        grid-template-columns: 1fr auto;
        grid-column-gap: 56px;
        grid-row-gap: 24px;
    }
}
.footer__copyright, .footer__contacts {
    @media (max-width: $tablet-l-max) {
        line-height: 14px;
        font-size: 10px;
    }
    @media (min-width: $desktop-min) {
        line-height: 19px;
        font-size: 14px;
    }
    & > p {
        margin: 0;
    }
}
.footer__counters {
    grid-column: 1 / -1;
    @media (min-width: $desktop-min) {
        grid-row: 1 / 1;
        margin-bottom: 28px;
    }
}
.footer__age {
    display: inline-block;
    font-weight: bold;
    margin-top: 32px;
    line-height: 13px;
    font-size: 12px;
}

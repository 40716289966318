.share {
    width: fit-content;
}
.share__item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
}
.share__item--fbk {
    color: #3F51B5 !important;
}
.share__item--vkt {
    color: #0288D1 !important;
}
.share__item--twt {
    color: #03A9F4 !important;
}
.share__item--okl {
    color: #ed812b !important;
}
.share__item--mlr {
    color: #168de2 !important;
}

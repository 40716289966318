.meta {
    display: flex;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: $text-light;
    & > * {
        &:nth-last-child(n+2) {
            margin-right: 8px;
        }
    }
    @media (max-width: $tablet-p-max) {
        line-height: 11px;
        font-size: 10px;
    }
    @media (min-width: $tablet-l-min) {
        line-height: 13px;
        font-size: 12px;
    }
}

.social {

}
.social--bw {
    --icon-color: #888888;
}
.social__title {
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    @media (max-width: $tablet-l-max) {
        line-height: 11px;
        font-size: 10px;
    }
    @media (min-width: $desktop-min) {
        line-height: 13px;
        font-size: 12px;
    }
}
.social__list {
    display: flex;
    margin-top: 12px;
    margin-left: -6px;
}
.social__link--fbk {
    color: var(--icon-color, #3F51B5) !important;
}
.social__link--vkt {
    color: var(--icon-color, #0288D1) !important;
}
.social__link--twt {
    color: var(--icon-color, #03A9F4) !important;
}
.social__link--tlg {
    color: var(--icon-color, #0088CC) !important;
}
.social__link--rss {
    color: var(--icon-color, #FF9800) !important;
}
.social__link--okl {
    color: var(--icon-color, #ed812b) !important;
}
.social__icon {
    fill: currentColor;
}

.card {

}
.card__img {
    display: block;
    & > img {
        display: block;
    }
}
.card__img--compact {
    max-width: 160px;
}
.card__meta {
    margin-top: 8px;
    color: #AAAAAA;
}
.card__views {
    display: flex;
    align-items: center;
    & > svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
}
.card__title {
    display: inline-block;
    margin-top: 4px;
    font-weight: 800;
    &:not(:hover) {
        color: #EEEEEE;
    }
    &:hover {
        color: #FFFFFF;
    }
    @media (max-width: $tablet-l-max) {
        line-height: 17px;
        font-size: 16px;
    }
    @media (min-width: $desktop-min) {
        line-height: 21px;
        font-size: 20px;
    }
}
.card__title--compact {
    line-height: 16px;
    font-size: 14px;
}

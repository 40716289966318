.content__partners {
    margin-bottom: 32px;
}
.content__sidebar {
    margin-bottom: 32px;
    & > * {
        &:nth-child(n+2) {
            margin-top: 32px;
        }
    }
    @media (max-width: $mobile-max) {
        margin-left: -16px;
        margin-right: -16px;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        margin-left: -24px;
        margin-right: -24px;
    }
}
.content__sidebar--article {
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        margin-left: 0;
        margin-right: 0;
    }
}
.content__sidebar-box {
    @media (max-width: $mobile-max) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
.content__sidebar-box--sticky {
    @media (min-width: $tablet-p-min) {
        position: sticky;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-l-max) {
        top: 0;
    }
    @media (min-width: $desktop-min) {
        // 58px - calculated header height
        top: calc(58px + 32px);
    }
}
.content__body {
    padding-bottom: 32px;
    @media (max-width: $tablet-p-max) {
        margin-top: 32px;
    }
    & > * {
        &:nth-child(n+2) {
            margin-top: 32px;
        }
    }
}
@media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
    .content--article {
        display: grid;
        grid-column-gap: var(--gap);
        grid-template-columns: repeat(3, 1fr);
    }
    .content__body {
        grid-column: 1 / 3;
        grid-row: 1 / 1;
    }
    .content__sidebar {
        grid-column: 3 / 3;
        grid-row: 1 / 1;
        padding-top: 32px;
    }
}
@media (min-width: $tablet-l-min) {
    .content {
        display: grid;
        grid-column-gap: var(--gap);
        grid-template-columns: repeat(4, 1fr);
    }
    .content__partners {
        grid-row: -1 / -2;
        grid-column: 1 / -1;
    }
    .content__body {
        grid-column: 1 / 4;
        grid-row: 1 / 1;
    }
    .content__sidebar {
        grid-column: 4 / 4;
        grid-row: 1 / 1;
    }
}

.popup {
    position: relative;
    max-width: 668px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 24px 40px;
    background-color: #ffffff;
}
.popup__header {
    padding-right: 40px;

    font-weight: 800;
    @media (max-width: $mobile-max) {
        line-height: 30px;
        font-size: 24px;
    }
    @media (min-width: $tablet-p-min) {
        line-height: 40px;
        font-size: 32px;
    }
}
.popup__content {
    @media (max-width: $mobile-max) {
        margin-top: 48px;
    }
    @media (min-width: $tablet-p-min) {
        margin-top: 32px;
    }
}
.popup__close {
    top: 24px !important;
    right: 24px !important;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23000'%3E%3Cpath d='M0 22.588L22.588 0 24 1.412 1.412 24z'/%3E%3Cpath d='M22.588 24L0 1.412 1.412 0 24 22.588z'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 24px !important;
}

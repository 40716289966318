.comments__list {
    m-nl();
    margin-top: 20px;
    & > * {
        &:nth-child(n+2) {
            margin-top: 20px;
        }
    }
}
.comments__toggle {
    --arrow-transform: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    &::before,
    &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 31 18' fill='%23FF7A00' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.969 2.594l13.75 13.75.898.86.899-.86 13.75-13.75L28.469.797 15.617 13.648 2.766.797.969 2.594z'/%3E%3C/svg%3E");
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: rotate(var(--arrow-transform));
        transition-property: transform;

    }
    &::before {
        margin-right: 16px;
    }
    &::after {
        margin-left: 16px;
    }
}
.comments__toggle--active {
    --arrow-transform: -180deg;
}
.comments__toggle + .comments__panel {
    display: none;
}
.comments__toggle--active + .comments__panel {
    display: block;
}
.comments__panel {
    padding-top: 20px;
}

.info__title {
    font-weight: 800;
    margin-top: 0;
}

@media (max-width: $tablet-l-max) {
    .info {
        line-height: 21px;
        font-size: 16px;
    }
    .info__title {
        line-height: 31px;
        font-size: 32px;
    }
}
@media (min-width: $desktop-min) {
    .info {
        line-height: 25px;
        font-size: 18px;
    }
    .info__title {
        line-height: 51px;
        font-size: 50px;
    }
}

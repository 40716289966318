@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('../fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

.subscribe {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.subscribe__header {
  width: 100%;
  margin-bottom: 2px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  line-height: 14px;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: $text-light;
}
.subscribe__link {
  margin-left: 4px;
  margin-right: 4px;
  padding: 6px;
}
.subscribe__icon {
  display: block;
  width: auto;
  height: 24px;
}

.also {
    display: grid;
    grid-gap: 16px;
    padding: 16px;
    background-color: #ffffff;
}
.also__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
        flex-shrink: 0;
    }
}
.also__item:nth-child(1) {
    --category-display: block;
}
.also__item:nth-child(n+2) {
    --category-display: none;
}
.also__meta {
    margin-bottom: 4px;
}
.also__meta > a {
    display: var(--category-display);
}
.also__title {
    line-height: 17px;
    font-size: 16px;
    font-weight: 600;
    &:not(:hover) {
        color: $text;
    }
    &:hover {
        color: $text-alt;
    }
    &:visited {
        color: $text-placeholder;
    }
    @media (min-width: $tablet-p-min) and (max-width: $tablet-p-max) {
        line-height: 15px;
        font-size: 14px;
    }
}

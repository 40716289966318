.gallery {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.gallery__slide {
  display: flex;
  justify-content: center;
}
.gallery__button {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #636363;
  background-repeat: no-repeat;
  cursor: pointer;
  @media (max-width: $tablet-p-max) {
    width: 32px;
    height: 32px;
    background-size: 12px;
  }
  @media (min-width: $tablet-l-min) {
    width: 48px;
    height: 48px;
    background-size: 17px 30px;
  }
}
.gallery__button--next {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.21094 29.6484L15.9609 15.8984L16.8203 15L15.9609 14.1016L2.21094 0.351562L0.414063 2.14844L13.2656 15L0.414062 27.8516L2.21094 29.6484Z' fill='white'/%3E%3C/svg%3E%0A");
  @media (max-width: $mobile-max) {
    right: calc(var(--gap) * -1);
  }
  @media (min-width: $tablet-p-min) {
    right: 0;
  }
  @media (max-width: $tablet-p-max) {
    background-position: 12px center;
  }
  @media (min-width: $tablet-l-min) {
    background-position: 18px center;
  }
}
.gallery__button--prev {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7891 0.351562L1.03906 14.1016L0.179688 15L1.03906 15.8984L14.7891 29.6484L16.5859 27.8516L3.73438 15L16.5859 2.14844L14.7891 0.351562Z' fill='white'/%3E%3C/svg%3E%0A");
  @media (max-width: $mobile-max) {
    left: calc(var(--gap) * -1);
  }
  @media (min-width: $tablet-p-min) {
    left: 0;
  }
  @media (max-width: $tablet-p-max) {
    background-position: 8px center;
  }
  @media (min-width: $tablet-l-min) {
    background-position: 14px center;
  }
}
.gallery__button--disabled {
  opacity: .2;
  pointer-events: none;
}

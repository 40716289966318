.loader {
    width: 40px;
    height: 40px;
    animation: loader 1.2s ease infinite;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(' __static__general/spinner.svg');
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

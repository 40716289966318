.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 16px 16px;
    color: $text-placeholder;
    background-color: #ffffff;
    box-shadow: inset 0 -1px #DDDDDD, inset 0 1px #DDDDDD;
}
.partners__header {
    align-self: flex-start;
    margin-bottom: 10px;
    line-height: 11px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}
.partners__info {
    align-self: flex-start;
    margin-top: 8px;
    line-height: 11px;
    font-size: 10px;
    &:not(:hover) {
        color: $text-placeholder;
    }
    &:hover {
        color: $text-light;
    }
}
